import React from "react"

export default (color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 18.509 18.124"
  >
    <g id="noun_day_1742379" transform="translate(-2.727 -3.6)">
      <g id="Group_92" data-name="Group 92" transform="translate(2.727 3.6)">
        <path
          id="Path_295"
          data-name="Path 295"
          d="M5.573,44.5l-.293-.664a.242.242,0,0,1,.059-.273l.547-.488a.239.239,0,0,0-.176-.41l-.723.078a.212.212,0,0,1-.234-.137l-.312-.664a.227.227,0,0,0-.43.039l-.156.723a.208.208,0,0,1-.2.176l-.723.078a.233.233,0,0,0-.1.43l.625.371a.268.268,0,0,1,.117.254l-.137.723a.235.235,0,0,0,.391.215l.547-.488a.274.274,0,0,1,.273-.039l.644.352C5.437,44.912,5.651,44.7,5.573,44.5Z"
          transform="translate(-2.727 -34.346)"
          fill={`${color}`}
        />
        <path
          id="Path_296"
          data-name="Path 296"
          d="M16.485,18.512a.158.158,0,0,1,.078.176l-.1.508a.167.167,0,0,0,.273.156L17.13,19a.18.18,0,0,1,.2-.02l.449.254a.168.168,0,0,0,.234-.215l-.215-.469a.186.186,0,0,1,.039-.2L18.2,18a.161.161,0,0,0-.137-.273l-.508.059a.155.155,0,0,1-.156-.1l-.215-.469a.168.168,0,0,0-.312.039l-.117.508a.193.193,0,0,1-.137.137l-.508.059a.168.168,0,0,0-.059.312Z"
          transform="translate(-13.385 -14.489)"
          fill={`${color}`}
        />
        <path
          id="Path_297"
          data-name="Path 297"
          d="M18.7,72.063l-.488-.156a.17.17,0,0,1-.117-.156l-.02-.508a.165.165,0,0,0-.293-.1l-.293.41a.158.158,0,0,1-.176.059l-.488-.156a.163.163,0,0,0-.176.254l.312.41a.177.177,0,0,1,0,.2l-.293.43a.169.169,0,0,0,.2.254l.488-.156a.158.158,0,0,1,.176.059l.312.41a.164.164,0,0,0,.293-.1v-.508a.149.149,0,0,1,.117-.156l.488-.176A.172.172,0,0,0,18.7,72.063Z"
          transform="translate(-13.904 -57.904)"
          fill={`${color}`}
        />
        <path
          id="Path_298"
          data-name="Path 298"
          d="M48.605,6.725a.608.608,0,0,0,.605-.605V4.205a.605.605,0,1,0-1.211,0V6.119A.636.636,0,0,0,48.605,6.725Z"
          transform="translate(-39.158 -3.6)"
          fill={`${color}`}
        />
        <path
          id="Path_299"
          data-name="Path 299"
          d="M48.605,80.4a.608.608,0,0,0-.605.605v1.914a.605.605,0,1,0,1.211,0V81.005A.608.608,0,0,0,48.605,80.4Z"
          transform="translate(-39.158 -65.401)"
          fill={`${color}`}
        />
        <path
          id="Path_300"
          data-name="Path 300"
          d="M72.816,70.776a.594.594,0,1,0-.84.84l1.348,1.348a.613.613,0,0,0,.859,0,.589.589,0,0,0,0-.84Z"
          transform="translate(-58.31 -57.515)"
          fill={`${color}`}
        />
        <path
          id="Path_301"
          data-name="Path 301"
          d="M84.019,46.9H82.105a.605.605,0,0,0,0,1.211h1.914a.605.605,0,1,0,0-1.211Z"
          transform="translate(-66.116 -38.443)"
          fill={`${color}`}
        />
        <path
          id="Path_302"
          data-name="Path 302"
          d="M72.386,18.939a.606.606,0,0,0,.43-.176l1.348-1.348a.594.594,0,0,0-.84-.84l-1.348,1.348a.589.589,0,0,0,0,.84A.6.6,0,0,0,72.386,18.939Z"
          transform="translate(-58.31 -13.9)"
          fill={`${color}`}
        />
        <path
          id="Path_303"
          data-name="Path 303"
          d="M30.6,24.4a5,5,0,1,0,5,4.98A5,5,0,0,0,30.6,24.4Zm-3.789,4.98A3.792,3.792,0,0,1,30.6,25.591v7.6A3.8,3.8,0,0,1,26.811,29.38Z"
          transform="translate(-21.133 -20.338)"
          fill={`${color}`}
        />
      </g>
    </g>
  </svg>
)
