import React from "react"

export default (color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="62.029"
    height="80.769"
    viewBox="0 0 62.029 80.769"
  >
    <g id="noun_engineer_216059" transform="translate(-13.857 -4.487)">
      <path
        id="Path_5"
        data-name="Path 5"
        d="M44.872,44.868c-6.83,0-12.474-7.4-13.381-17.016H29.633C30.665,38.8,37.093,47.234,44.872,47.234S59.078,38.8,60.11,27.851H58.253C57.346,37.464,51.7,44.868,44.872,44.868Z"
        fill={`${color}`}
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M75.886,65.5a20.47,20.47,0,0,0-17.62-20.272c-.034,0-.071-.008-.107-.014-.284-.036-.572-.071-.86-.1,0,.03-.01.059-.011.088,0,.084-.019.166-.029.252a4.141,4.141,0,0,1-.42,1.392c-1.5,3.787-6.371,10.964-9.236,15.037l-.591-8.464a4.955,4.955,0,0,0,2.018-1.964,3.906,3.906,0,0,0,.536-1.9,12.242,12.242,0,0,1-4.32.948c-.125,0-.249,0-.373-.011s-.249.011-.373.011a12.236,12.236,0,0,1-4.32-.948,3.905,3.905,0,0,0,.535,1.9,4.955,4.955,0,0,0,2.018,1.964l-.591,8.464C39.274,57.81,34.408,50.633,32.9,46.846a4.123,4.123,0,0,1-.421-1.392c-.01-.086-.025-.168-.029-.252,0-.029-.009-.058-.01-.088-.289.027-.576.061-.86.1-.037.006-.074.01-.108.014A20.472,20.472,0,0,0,13.857,65.5c0,7.239,3.761,9.829,9.432,10.758A5.131,5.131,0,0,1,28.4,71.622h22a10.128,10.128,0,0,1,8.711-4.983h.007a10.058,10.058,0,0,1,6.844,2.68l.516.477v4.563H59.934l-1.386,1.386v1.024C68.367,76.681,75.886,75.737,75.886,65.5Z"
        fill={`${color}`}
      />
      <path
        id="Path_7"
        data-name="Path 7"
        d="M28.907,25.574H60.834a2.252,2.252,0,1,0,0-4.5h-.613a15.348,15.348,0,0,0-8.42-13.7L51.344,9.97l-.815,4.618a1.177,1.177,0,0,1-1.157.974,1.152,1.152,0,0,1-.206-.019,1.175,1.175,0,0,1-.954-1.363l.9-5.089.555-3.15A3.077,3.077,0,0,0,47.05,4.487H42.693A3.081,3.081,0,0,0,40.078,5.94l.556,3.15.9,5.089a1.175,1.175,0,0,1-.954,1.363,1.142,1.142,0,0,1-.2.019,1.177,1.177,0,0,1-1.159-.974L38.4,9.971l-.458-2.594A15.343,15.343,0,0,0,29.523,21.07h-.615a2.252,2.252,0,0,0,0,4.5Z"
        fill={`${color}`}
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M56.942,75.08l2.326-2.328h5.6V70.5a8.453,8.453,0,0,0-5.753-2.253H59.1a8.485,8.485,0,0,0-7.729,4.982H28.4a3.524,3.524,0,0,0-.006,7.048H28.4l22.975,0A8.492,8.492,0,0,0,64.87,83V80.746h-5.6l-2.323-2.323ZM28.4,78.454a1.7,1.7,0,1,1,1.7-1.7A1.705,1.705,0,0,1,28.4,78.454Z"
        fill={`${color}`}
      />
    </g>
  </svg>
)
