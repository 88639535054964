import React from "react"

export default (color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 16 12.999"
  >
    <path
      id="github"
      d="M14.7,5.34a4.513,4.513,0,0,0-.13-3.31s-1.05-.33-3.44,1.3A11.645,11.645,0,0,0,8,3.01a11.645,11.645,0,0,0-3.13.32c-2.39-1.64-3.44-1.3-3.44-1.3A4.513,4.513,0,0,0,1.3,5.34,4.786,4.786,0,0,0,0,8.69C0,13.84,3.33,15,7.98,15S16,13.84,16,8.69a4.786,4.786,0,0,0-1.3-3.35ZM8,14.02c-3.3,0-5.98-.15-5.98-3.35A2.851,2.851,0,0,1,3.04,8.6c1.07-.98,2.9-.46,4.96-.46s3.88-.52,4.96.46a2.8,2.8,0,0,1,1.02,2.07c0,3.19-2.68,3.35-5.98,3.35ZM5.49,9.01c-.66,0-1.2.8-1.2,1.78s.54,1.79,1.2,1.79,1.2-.8,1.2-1.79S6.15,9.01,5.49,9.01Zm5.02,0c-.66,0-1.2.79-1.2,1.78s.54,1.79,1.2,1.79,1.2-.8,1.2-1.79S11.18,9.01,10.51,9.01Z"
      transform="translate(0 -2.001)"
      fill={`${color}`}
      fillRule="evenodd"
    />
  </svg>
)
